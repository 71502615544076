<template>
  <!-- <v-system-bar color="deep-purple darken-3"></v-system-bar> -->
  <v-app-bar style="background: #0f1c30" app dark fixed>
    <v-app-bar-nav-icon
      v-if="isMobile"
      variant="text"
      @click.stop="drawerHeader = !drawerHeader"
      class="tertiary-color"
    ></v-app-bar-nav-icon>
    <v-app-bar-nav-icon v-else class="d-flex justify-center aling-center">
      <v-img
        src="images/logo2.png"
        width="80"
        class="ml-8"
        @click="scrollToSection('home')"
      ></v-img>
    </v-app-bar-nav-icon>
    <v-app-bar-title class="tertiary-color hidden-md-and-up"
      >PMT
    </v-app-bar-title>

    <v-spacer></v-spacer>

    <v-btn
      variant="text"
      class="tertiary-color hidden-sm-and-down"
      @click="scrollToSection('home')"
      >Home</v-btn
    >
    <v-btn
      variant="text"
      class="tertiary-color hidden-sm-and-down"
      @click="scrollToSection('projects')"
      >Projects</v-btn
    >
    <v-btn
      variant="text"
      class="tertiary-color hidden-sm-and-down"
      @click="scrollToSection('aboutus')"
      >About Us</v-btn
    >
    <v-btn
      variant="text"
      class="tertiary-color hidden-sm-and-down"
      @click="scrollToSection('contactus')"
      >Contact Us</v-btn
    >
  </v-app-bar>

  <v-navigation-drawer
    v-model="drawerHeader"
    v-if="shouldShowDrawer"
    location="left"
  >
    <v-list-item class="d-flex align-center justify-center">
      <v-img width="100" src="images/logo4.png"></v-img>
    </v-list-item>
    <!-- <v-list-item
      title="PMT"
      class="text-center primary-color"
    ></v-list-item> -->
    <v-divider></v-divider>
    <v-list-item
      link
      title="Home"
      class="secondary-color"
      @click="scrollToSection('home')"
    ></v-list-item>
    <v-list-item
      link
      title="Projects"
      class="secondary-color"
      @click="scrollToSection('projects')"
    ></v-list-item>
    <v-list-item
      link
      title="Contact Us"
      class="secondary-color"
      @click="scrollToSection('contactus')"
    ></v-list-item>
    <v-list-item
      link
      title="About Us"
      class="secondary-color"
      @click="scrollToSection('aboutus')"
    ></v-list-item>
  </v-navigation-drawer>

  <v-main style="min-height: 200vh" id="home">
    <v-img
      src="images/pool/p2_1.jpg"
      height="100vh"
      cover
      class="bg-grey-lighten-4 dark-overlay-image-header"
    >
      <v-container class="overlay-content-header">
        <v-row style="height: 100vh">
          <v-col
            cols="12"
            class="d-flex flex-column align-center justify-center mb-16"
          >
            <v-container class="my-0 py-0">
              <v-row class="my-0 py-0">
                <v-col
                  class="d-flex flex-column align-center justify-center my-0 py-0"
                >
                  <v-img
                    src="images/logo.png"
                    width="170"
                    class="my-0 py-0"
                  ></v-img>
                  <h2 class="my-4 title" style="color: white">Puga Masonry and Tile Landscape and Hardscape Designs</h2>
                </v-col>
              </v-row>
            </v-container>
            <v-btn
              size="large"
              class="secondary-color font-weight-bold mb-16"
              @click="scrollToSection('projects')"
            >
              Projects
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-img>

    <v-row class="py-8 mx-0" id="tuIdDeSeccion">
      <v-col
        cols="12"
        sm="12"
        md="6"
        class="d-flex flex-column align-center justify-center py-12 px-12"
      >
        <h1 class="secondary-color">PUGA TILE</h1>
        <p class="text-center py-2 px-16 secondary-color font-weight-bold">
          Puga Tile We offer All Type of Masonry work, Floor tile, Firepits,
          Fireplaces and more. We can defenetly make you dream come true
          reality.
        </p>
        <!-- <v-btn class="primary-background tertiary-color"> learn more </v-btn> -->
      </v-col>
      <v-divider
        v-if="isMobile"
        :thickness="1"
        color="#173c51"
        class="border-opacity-50"
        horizontal
      ></v-divider>
      <v-divider
        v-else
        :thickness="1"
        color="#173c51"
        class="border-opacity-50"
        vertical
      ></v-divider>
      <v-col
        cols="12"
        sm="12"
        md="6"
        class="d-flex align-center justify-center py-12 px-12"
      >
        <v-row id="sectionNumberCount">
          <v-col cols="6" sm="4" md="4"
            ><h1 class="text-center primary-color text-h3 font-weight-bold">
              +{{ animatedData1 }}
            </h1>
            <p class="text-center secondary-color font-weight-bold">
              Years Of Experience
            </p></v-col
          >
          <v-col cols="6" sm="4" md="4"
            ><h1 class="text-center primary-color text-h3 font-weight-bold">
              +{{ animatedData2 }}
            </h1>
            <p class="text-center secondary-color font-weight-bold">
              Clients
            </p></v-col
          >
          <v-col cols="12" sm="4" md="4"
            ><h1 class="text-center primary-color text-h3 font-weight-bold">
              +{{ animatedData3 }}
            </h1>
            <p class="text-center secondary-color font-weight-bold">
              Fullfilled Projects
            </p></v-col
          >
        </v-row>
      </v-col>
    </v-row>

    <!-- SERVICES WE SPECIALIZE IN -->
    <v-row class="primary-background mx-0">
      <v-col cols="12">
        <h1 class="text-center tertiary-color">
          <label class="border-bottom"> SERVICES WE SPECIALIZE IN </label>
        </h1>
        <div class="d-flex align-center justify-center"></div>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="4"
        class="d-flex flex-column align-center justify-center"
      >
        <v-icon style="color: white" :size="150">mdi-hammer-wrench</v-icon>
        <h1 style="color: white" class="text-center">Swimming Pool Tile</h1>
        <!-- <v-btn variant="text" style="color: white"
          >lear more <v-icon>mdi-plus-circle</v-icon></v-btn
        > -->
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="4"
        class="d-flex flex-column align-center justify-center"
      >
        <v-icon style="color: white" :size="150">mdi-set-square</v-icon>
        <h1 style="color: white" class="text-center">Travertine Decks</h1>
        <!-- <v-btn variant="text" style="color: white"
          >lear more <v-icon>mdi-plus-circle</v-icon></v-btn
        > -->
      </v-col>
      <v-col
        cols="12"
        sm="12"
        lg="4"
        class="d-flex flex-column align-center justify-center"
      >
        <v-icon style="color: white" :size="150">mdi-toolbox-outline</v-icon>
        <h1 style="color: white" class="text-center">
          All Type Of Masonry Walls
        </h1>
        <!-- <v-btn variant="text" style="color: white"
          >lear more <v-icon>mdi-plus-circle</v-icon></v-btn
        > -->
      </v-col>

      <v-col cols="12" class="d-flex align-center justify-center">
        <v-btn class="primary-color" @click="openAllServices = true">
          all services
        </v-btn>
      </v-col>
    </v-row>

    <!-- FULFILLED PROJECTS -->
    <v-row class="pt-8 mx-0" id="projects">
      <v-col cols="12" class="text-center"
        ><h1 class="secondary-color elemento-a-revelar">
          <label class="border-bottom"> FULFILLED PROJECTS </label>
        </h1></v-col
      >
      <v-col
        sm="6"
        lg="4"
        v-for="(fulfilledProject, index) in computedfulFilledProjects"
        :key="index"
      >
        <v-card
          class="mx-auto elemento-a-revelar"
          max-width="450"
          elevation="4"
        >
          <v-img :src="fulfilledProject.image" height="200px" cover></v-img>

          <v-card-title class="primary-color">
            {{ fulfilledProject.title }}
          </v-card-title>

          <!-- <v-card-text>
            <div class="my-4 text-subtitle-1 secondary-color">
              {{ fulfilledProject.comment }}
            </div>
          </v-card-text> -->

          <v-card-actions class="d-flex align-center justify-center">
            <v-btn
              class="primary-background tertiary-color"
              @click="openModalProjectDetail(fulfilledProject)"
            >
              View
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" class="d-flex align-center justify-center my-12">
        <v-btn
          class="primary-background tertiary-color"
          @click="showAllProjects"
        >
          {{ quantityProject.isAll ? "hide" : "all" }} projects
        </v-btn>
      </v-col>
    </v-row>

    <!-- About us -->
    <v-row id="aboutus" class="mx-0">
      <v-col cols="12">
        <v-row>
          <v-col cols="12" md="4" class="tertiary-background">
            <v-row>
              <v-col cols="12" class="py-6">
                <h1 class="fourth-color px-4 text-start">
                  Innovative Custom Pool
                </h1>
                <p class="fourth-color px-4 text-start">
                  Where Your Imagination Takes the Plunge! At Puga Tile, we are
                  the maestros of transforming aquatic dreams into breathtaking
                  realities. Our commitment to unparalleled craftsmanship and
                  attention to detail sets us apart as the go-to experts for all
                  your custom pool needs. Explore our spectrum of services.
                </p>
              </v-col>
              <v-divider
                :thickness="1"
                color="#ffffff"
                class="border-opacity-50"
                horizontal
              ></v-divider>
              <v-col cols="12" class="py-6">
                <h1 class="fourth-color px-4 text-start">
                  High Quality Materials & Building Practices
                </h1>
                <p class="fourth-color px-4 text-start">
                  We pride ourselves on being the pioneers of superior
                  craftsmanship, setting industry standards with an unwavering
                  commitment to using the finest materials and building
                  practices. Our dedication to quality resonates through every
                  facet of our work.
                </p>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            md="8"
            class="my-0 mx-0 py-0 px-0 d-flex align-center justify-center"
          >
            <v-carousel
              :show-arrows="false"
              class="dark-overlay-image"
              cycle
              hide-delimiters
            >
              <v-carousel-item
                v-for="(imageWeAre, index) in imagesWeAre"
                :key="index"
                :src="imageWeAre"
                cover
              >
              </v-carousel-item>
              <div class="overlay-content">
                <h1 class="tertiary-color text-center mt-16 mb-8">
                  Puga Tile Since 1994
                </h1>
                <p class="tertiary-color text-center px-16">
                  Welcome to Puga Tile, where craftsmanship meets excellence! We
                  take pride in offering a comprehensive range of top-notch
                  masonry services to turn your visions into reality.
                  Specializing in various facets of masonry work, we are your
                  go-to experts for transforming spaces with timeless elegance.
                </p>
              </div>
            </v-carousel>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- testimonial -->
    <v-row class="primary-background mx-0">
      <v-col cols="12">
        <h1 class="text-center tertiary-color elemento-a-revelar">
          <label class="border-bottom"> TESTIMONIALS </label>
        </h1>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        v-for="testimonial in testimonials"
        :key="testimonial.id"
      >
        <v-card
          class="mx-auto d-flex flex-column"
          max-width="450"
          elevation="4"
          fluid
        >
          <v-card-item>
            <v-card-title class="font-weight-bold primary-color">{{
              testimonial.author
            }}</v-card-title>
          </v-card-item>

          <v-card-text>
            <v-row align="center" class="mx-0">
              <v-rating
                :model-value="testimonial.qualification"
                color="amber"
                density="compact"
                half-increments
                readonly
                size="small"
              ></v-rating>
            </v-row>

            <div class="my-4 text-subtitle-1 secondary-color">
              {{ testimonial.comment }}
            </div>
          </v-card-text>

          <v-divider class="mx-4 mb-1"></v-divider>
          <v-card-title class="primary-color">
            {{ testimonial.title }}
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <!-- information -->
    <v-row id="contactus" class="mx-0">
      <v-col cols="12">
        <h1 class="text-center secondary-color elemento-a-revelar">
          <label class="border-bottom"> CONTACT US </label>
        </h1>
      </v-col>
      <v-col cols="12" class="my-6">
        <h3 class="secondary-color text-center elemento-a-revelar">
          Hours of operation
        </h3>
        <h3 class="secondary-color text-center elemento-a-revelar">
          Monday-Saturday: 8:00 am - 3:00 pm
        </h3>
      </v-col>
      <v-col cols="12" class="my-6">
        <h3 class="secondary-color text-center elemento-a-revelar">
          <v-icon>mdi-map-marker</v-icon> 5412 W Lamar Rd,
        </h3>
        <h3 class="secondary-color text-center elemento-a-revelar">
          Glendale, AZ 85301, EE. UU.
        </h3>
      </v-col>
      <v-col cols="12" class="my-6">
        <h3 class="secondary-color text-center elemento-a-revelar">
          <v-icon>mdi-phone</v-icon> Telephone: +1 602 882 9225
        </h3>
        <h3 class="secondary-color text-center elemento-a-revelar">
          <v-icon>mdi-email</v-icon> Email: pugatile01@gmail.com
        </h3>
      </v-col>
      <v-col
        cols="12"
        class="d-flex align-center justify-center my-4 elemento-a-revelar"
      >
        <v-btn variant="text" style="height: 100%" @click="goToFacebook">
          <v-icon :size="50" class="mx-4 secondary-color">mdi-facebook</v-icon>
        </v-btn>
        <v-btn
          variant="text"
          style="height: 100%"
          class="mx-4"
          @click="goToEmail"
        >
          <v-icon :size="50" class="mx-4 secondary-color">mdi-email</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- Mapa -->
    <v-row class="mx-0">
      <v-col cols="12" class="py-0 px-0 mx-0">
        <div>
          <iframe
            :src="
              'https://maps.google.com/maps?width=100%&height=00&hl=es&q=' +
              33.53696588621077 +
              ',' +
              -112.17584112885002 +
              '&ie=UTF8&t=&z=22&iwloc=B&z=15&output=embed'
            "
            width="100%"
            height="500px"
            frameborder="0"
            style="border: 0"
            allowfullscreen
          >
          </iframe>
        </div>
      </v-col>
    </v-row>

    <!-- footer -->
    <v-footer class="primary-background py-4">
      <v-row justify="center" no-gutters>
        <div class="tertiary-color">
          <footer>
            <span>&copy; {{ date }} </span>
            <strong> PUGA TILE </strong>
            <span>All Rights Reserved</span>
          </footer>
        </div>
      </v-row>
    </v-footer>
  </v-main>

  <v-dialog v-model="openProjects" min-width="300" max-width="900">
    <v-card>
      <v-carousel hide-delimiters show-arrows="hover" cycle>
        <v-carousel-item
          v-for="(imageProject, index) in imagesProject"
          :key="index"
          :src="imageProject"
          cover
        ></v-carousel-item>
      </v-carousel>

      <v-card-actions class="justify-center">
        <v-btn
          class="primary-background tertiary-color"
          variant="text"
          @click="openProjects = false"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="openAllServices" min-width="300" max-width="500">
    <v-card>
      <v-list>
        <v-list-subheader inset>ALL SERVICES</v-list-subheader>

        <v-list-item
          v-for="(service, i) in allServices"
          :key="i"
          :value="service"
          class="secondary-color"
        >
          <template v-slot:prepend>
            <v-avatar color="green" size="30">
              <v-icon color="white">mdi-check</v-icon>
            </v-avatar>
          </template>

          {{ service }}
        </v-list-item>
      </v-list>
      <v-card-actions class="justify-end">
        <v-btn class="secondary-color" @click="openAllServices = false"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TweenLite from "gsap";
import ScrollReveal from "scrollreveal";

export default {
  name: "PrincipalPage",
  mounted() {
    this.mobile();
    // Escuchar cambios en el tamaño de la pantalla
    window.addEventListener("resize", this.mobile);
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("scroll", this.handleScrollVisible);
    window.addEventListener("touchstart", this.handleScrollVisible);
    window.addEventListener("touchmove", this.handleScrollVisible);
    ScrollReveal().reveal(".elemento-a-revelar", {
      // Opciones de configuración, por ejemplo:
      duration: 1000, // Duración de la animación en milisegundos
      easing: "ease-in-out", // Tipo de interpolación
      distance: "0", // Distancia del desplazamiento
    });
    window.addEventListener("resize", this.handleResize);
    setTimeout(() => {
      this.loadingMaps = true;
    }, 1000);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScrollVisible);
    window.removeEventListener("touchstart", this.handleScrollVisible);
    window.removeEventListener("touchmove", this.handleScrollVisible);
    ScrollReveal().destroy();
  },
  data: () => ({
    date: new Date().getFullYear(),
    drawer: false,
    group: null,
    isMobile: false,
    openProjects: false,
    fulfilledProjects: [
      {
        image: "images/pool/p_1.jpeg",
        title: "Posted on Dec 11th, 2023",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: [
          "images/pool/p_1.jpeg",
          "images/pool/p_2.jpeg",
          "images/pool/p_3.jpeg",
        ],
      },
      {
        image: "images/pool/p8.jpg",
        title: "Posted on Apr 15th, 2015",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p8.jpg"],
      },
      {
        image: "images/pool/p7.jpg",
        title: "Posted on Oct 26th, 2016",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p7.jpg"],
      },
      {
        image: "images/pool/p2_1.jpg",
        title: "Posted on Feb 23th, 2017",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p2_1.jpg"],
      },
      {
        image: "images/pool/p9.jpg",
        title: "Posted on Apr 11th, 2022",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p9.jpg"],
      },
      {
        image: "images/pool/p2_4.jpg",
        title: "Posted on Oct 14th, 2013",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p2_4.jpg"],
      },
      {
        image: "images/pool/p10.jpg",
        title: "Posted on Aug 31th, 2020",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p10.jpg"],
      },
      {
        image: "images/pool/p2_3.jpg",
        title: "Posted on Jun 24th, 2016",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p2_3.jpg"],
      },
      {
        image: "images/pool/p12.jpg",
        title: "Posted on Dec 11th, 2022",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p12.jpg"],
      },
      {
        image: "images/pool/p19.jpeg",
        title: "Posted on Dec 11th, 2022",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p19.jpeg"],
      },
      {
        image: "images/pool/p2_2.jpg",
        title: "Posted on May 26th, 2018",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p2_2.jpg"],
      },
      {
        image: "images/pool/p11.jpg",
        title: "Posted on Apr 11h, 2022",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p11.jpg"],
      },
      {
        image: "images/pool/p6.jpg",
        title: "Posted on Apr 21th, 2021",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p6.jpg"],
      },
      {
        image: "images/pool/p5.jpg",
        title: "Posted on Dec 30th, 2020",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p5.jpg"],
      },
      {
        image: "images/pool/p1_2.jpg",
        title: "Posted on Sep 17th, 2020",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p1_2.jpg"],
      },
      {
        image: "images/pool/p1_3.jpg",
        title: "Posted on Sep 8, 2020",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p1_3.jpg"],
      },
      {
        image: "images/pool/p1_4.jpg",
        title: "Posted on Oct 4, 2019",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p1_4.jpg"],
      },
      {
        image: "images/pool/p1_1.jpg",
        title: "Posted on May 15th, 2019",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p1_1.jpg"],
      },
      {
        image: "images/pool/p1_5.jpg",
        title: "Posted on Jan 15th, 2018",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p1_5.jpg"],
      },
      {
        image: "images/pool/p3.jpg",
        title: "Posted on May 11th, 2017",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p3.jpg"],
      },
      {
        image: "images/pool/p4.jpg",
        title: "Posted on Nov 2th, 2016",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p4.jpg"],
      },
      {
        image: "images/pool/p1_6.jpg",
        title: "Posted on Jun 8th, 2015",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p1_6.jpg"],
      },
      {
        image: "images/pool/p13.jpg",
        title: "Posted on Nov 13th, 2023",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p13.jpg"],
      },
      {
        image: "images/pool/p14.jpg",
        title: "Posted on Aug 15th, 2022",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p14.jpg"],
      },
      {
        image: "images/pool/p15.jpg",
        title: "Posted on Sep 29th, 2022",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p15.jpg"],
      },
      {
        image: "images/pool/p16.jpg",
        title: "Posted on May 14th, 2023",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p16.jpg"],
      },
      {
        image: "images/pool/p17_1.jpg",
        title: "Posted on Feb 2th, 2017",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: ["images/pool/p17_1.jpg", "images/pool/p17_2.jpg"],
      },
      {
        image: "images/pool/p18_1.jpg",
        title: "Posted on May 10th, 2017",
        comment:
          "Fermentum odio eu feugiat pretium nibh ipsum. Ornare arcu odio ut sem. Lectus sit amet est placerat in. ",
        images: [
          "images/pool/p18_1.jpg",
          "images/pool/p18_2.jpg",
          "images/pool/p18_3.jpg",
        ],
      },
    ],
    testimonials: [
      {
        id: 1,
        title: "Feb 24th, 2022",
        qualification: 5,
        comment:
          "Miguel and his crew did an amazing job with the travertine and stone install for our new pool build. It was a very complicated design with sunken BBQ, fire feature, fire pit, and jacuzzi. Throughout the project, Miguel would consult with me on detailed options/design which I greatly appreciated. His team was meticulous about ensuring everything was lined up and level. I would certainly recommend Puga tile for your project! Service: Tile work installation",
        author: "MICHAEL NAMIE",
      },
      {
        id: 2,
        title: "Feb 18th, 2022",
        qualification: 5,
        comment:
          " Miguel and his crew did a great job on my pool remodel. Showed up on time and worked all day. Very responsive to calls and made any changes we asked. Would highly recommend puga tile",
        author: "LUKE IMPERIAL",
      },
      {
        id: 3,
        title: "Feb 13th, 2022",
        qualification: 5,
        comment:
          "I had seen some examples of Miguel's work on some other pools and a close fiend of mine had his pool hardscaping done by Miguel and Puga Tile. Miguel and his crew are excellent craftsman. I cannot say enough about his travertine guys and the painstaking work they did to get the ABC subbase and sand top coat laid before putting the field tile down. Miguel's team showed up early and worked all day to get specific tasks done. He knows his stuff and the end product shows it. Services: Tile work installation, Plumbing fixture installation",
        author: "PAUL ZONNEVELD",
      },
      {
        id: 4,
        title: "Jul 26th, 2020",
        qualification: 5,
        comment:
          "Miguel and his crew laid 2 x 3 foot travertine tile pool decking, water line tile, set the waterfall and installed the brick on the back wall. It looks even better in person than it does in the pictures. I'm very picky when it comes to having perfect grout lines and each pieces being level with no lip. I have zero complaints on the quality and my expectations were met. He also built a couple block walls so we could add another gate to our side yard. It all turned out great.",
        author: "J HOOD",
      },
      {
        id: 5,
        title: "Feb 15th, 2019",
        qualification: 5,
        comment:
          "This past winter I did an owner built pool. A friend referred Miguel and I'm happy I secured him for my project. The work from him and his crew is excellent. While he did take a little longer than anticipated, he got the job done by my daughters graduation party (which was our deadline). He did the decking, water tile, waterfall, and walls/columns of the Ramada. Everything turned out beautiful. For the quality and value, I recommend Puga Tile.",
        author: "MICHAEL GABORICK",
      },
      {
        id: 6,
        title: "Feb 3rd, 2017",
        qualification: 5,
        comment:
          " We have had a wonderful experience with Puga Tile, Miguel, his Sons and the employees. We had paver blocks put in to extend our driveway. Just beautiful! We had paver blocks put in as a sidewalk for moving our trash cans to the street. Perfect. We had paver blocks put in for our trash cans to sit on, came out perfect! Lastly, we had paver blocks placed around our pool pump area. Exactly what we asked for. I highly recommend Puga Tile for your paver block and or tile job!",
        author: "CINDY FERRIN",
      },
    ],
    imagesWeAre: [
      "images/pool/p_1.jpeg",
      "images/pool/p_2.jpeg",
      "images/pool/p_3.jpeg",
    ],
    navBackgroundColor: "#26798e6d",
    lastScroll: 0,
    loadingMaps: false,
    animatedData1: 0,
    animatedData2: 0,
    animatedData3: 0,
    targetData1: 30,
    targetData2: 60,
    targetData3: 100,
    animationProgress1: 0,
    animationProgress2: 0,
    animationProgress3: 0,

    isVisible: false,
    drawerHeader: false,
    tab: null,
    shouldShowDrawer: true,

    openAllServices: false,
    imagesProject: [],
    quantityProject: {
      isAll: false,
      quantity: 6,
    },
    allServices: [
      "Swimming Pool Tile ",
      "Pool Coping",
      "Travertine Decks",
      "Paver Deck And Patio Also Driveways",
      "Masonry Walls",
      "Stucco",
      "Fireplaces",
      "Stone",
      "Firepits",
      "All Type Of Masonry Walls",
      "Custom Bbq's  Wherever Size And Choice Customer Want It",
    ],
  }),
  methods: {
    mobile() {
      this.isMobile = window.innerWidth < 960; // Ajusta este valor según tus necesidades
    },
    handleScroll() {
      const currentScroll = window.scrollY;

      if (currentScroll > 30) {
        // Scroll hacia abajo y no está en la parte superior
        this.navBackgroundColor = "#26798E";
      } else {
        // Scroll hacia arriba o está en la parte superior
        this.navBackgroundColor = "#26798e6d"; // Reemplaza con el color que desees
      }
    },
    animateCounter({ target, progress, targetCount }) {
      TweenLite.to(this.$data, 3, {
        // Ajusta la duración a tu preferencia
        currentCount: targetCount,
        onUpdate: () => {
          // Forzar la actualización de la vista
          // this.$forceUpdate();

          // Redondear el valor a un número entero
          this[target] = Math.round(this.currentCount);

          // Condición para detener la animación
          if (this.currentCount >= targetCount) {
            TweenLite.killTweensOf(this.$data, { currentCount: true });
          }
        },
        onComplete: () => {
          this[target] = targetCount;
          this[progress] = 0;
          this.animateCounter({ target, progress, targetCount });
        },
        ease: "Power1.easeOut", // Ajusta la función de easing a tu preferencia
      });
    },
    isSectionVisible() {
      const section = document.getElementById("sectionNumberCount");

      const rect = section.getBoundingClientRect();
      return rect.top >= 0 && rect.bottom <= window.innerHeight;
    },
    handleScrollVisible() {
      if (this.isSectionVisible() && !this.isVisible) {
        this.animateCounter({
          target: "animatedData1",
          progress: "animationProgress1",
          targetCount: this.targetData1,
        });
        this.animateCounter({
          target: "animatedData2",
          progress: "animationProgress2",
          targetCount: this.targetData2,
        });
        this.animateCounter({
          target: "animatedData3",
          progress: "animationProgress3",
          targetCount: this.targetData3,
        });

        this.isVisible = true;
      }
    },
    scrollToSection(id) {
      // Obtener la referencia a la sección por su ID
      const section = document.getElementById(id);

      // Verificar si la sección existe
      if (section) {
        // Usar el método scrollIntoView para desplazarse a la sección
        section.scrollIntoView({ behavior: "smooth" });
      }
    },
    handleResize() {
      // Determinar el tamaño de la ventana y controlar la visibilidad del cajón en pantallas de escritorio
      this.shouldShowDrawer = window.innerWidth < 960; // Ajusta el valor según tus necesidades
    },
    openModalProjectDetail(fulfilledProject) {
      this.imagesProject = fulfilledProject.images.map((project) => project);
      this.openProjects = true;
    },
    showAllProjects() {
      if (this.quantityProject.isAll) {
        this.quantityProject.quantity = 6;
        this.quantityProject.isAll = false;
      } else {
        this.quantityProject.quantity = this.fulfilledProjects.length + 1;
        this.quantityProject.isAll = true;
      }
    },
    goToFacebook() {
      window.open(
        "https://www.facebook.com/profile.php?id=61552756780019&is_tour_completed=true",
        "_blank"
      );
    },
    goToEmail() {
      window.location.href = "mailto:pugatile01@gmail.com";
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  computed: {
    computedfulFilledProjects() {
      return this.fulfilledProjects.slice(0, this.quantityProject.quantity);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.title {
  font-family: Rosarivo, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 36px;
}

.primary-background {
  /* background: #304a6e; */
  background: #0f1c30;
}

.secondary-background {
  /* background: #0f1c30; */
  background: #304a6e;
}

.tertiary-background {
  /* background: #0f1c30; */
  background: #304a6e;
}

.primary-color {
  /* color: #304a6e; */
  color: #0f1c30;
}

.secondary-color {
  color: #0f1c30;
}

.tertiary-color {
  color: white;
}

.fourth-color {
  color: white;
}

.elemento-a-revelar {
  opacity: 1;
  transform: translateY(0);
}

.dark-overlay-image {
  position: relative;
  min-height: 500px;
  max-height: 1000px;
}

.dark-overlay-image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Ajusta el valor de opacidad según tu preferencia */
}

.dark-overlay-image-header {
  position: relative;
  min-height: 500px;
  max-height: 1000px;
}

.dark-overlay-image-header::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(
    0,
    0,
    0,
    0.6
  ); /* Ajusta el valor de opacidad según tu preferencia */
}

.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 100%;
  text-align: center;
  padding: 16px;
  color: white; /* Asegúrate de que el texto sea visible sobre la imagen oscura */
}

.overlay-content-header {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 100%;
  text-align: center;
  padding: 16px;
  color: white; /* Asegúrate de que el texto sea visible sobre la imagen oscura */
}

.border-bottom {
  border-bottom: 2px solid;
}
</style>
