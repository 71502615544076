<template>
  <v-app>
    <Principal />
  </v-app>
</template>

<script>
import Principal from './components/Principal.vue'

export default {
  components: {
    Principal
  }
}
</script>

<style>

</style>
